import { ItemReorderEventDetail } from '@ionic/core';

export const canDismiss = async (_data: any, role?: string) =>
  role !== 'gesture';

export function getPresentingElement() {
  const presentingElement =
    document.querySelector('ion-router-outlet') ?? undefined;
  return presentingElement;
}

export function lines(
  arr: any[] | undefined,
  index: number,
  lastItem: HTMLIonItemElement['lines'] = 'full'
): HTMLIonItemElement['lines'] {
  return index + 1 === arr?.length ? lastItem : undefined;
}

export function onIonItemReorder<T>(
  items: T[],
  e: CustomEvent<ItemReorderEventDetail>
) {
  const fromIndex = e.detail.from;
  const toIndex = e.detail.to;
  const copyItems = [...items];
  const key = copyItems[fromIndex];
  copyItems.splice(fromIndex, 1);
  copyItems.splice(toIndex, 0, key);

  e.detail.complete(true);
  return [...copyItems];
}
